<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                   {{nombre}} 
                </v-card-title>
                <v-card-text>
                    <!-- ---------------------------------------------------- -->
                    <v-text-field
                        class="pt-2"
                        hint="Anote observaciones antes de seleccionar"
                        :persistent-hint="true"
                        v-model="observaciones"
                        filled
                        rounded
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>
                    <div class="d-flex pa-1 align-center">
                            <div>
                                <v-img
                                    height="50px"
                                    width="200"
                                    src="/images/escalas/isocoria.jpg"
                                />
                            </div>
                            <div class="ml-2">
                                <v-btn :disabled="loading" icon color="success" @click="agrega(1)">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                                Isocoria
                            </div>
                    </div>
                    <div class="d-flex pa-1 align-center">
                            <div>
                                <v-img
                                    height="50px"
                                    width="200"
                                    src="/images/escalas/midriasis.jpg"
                                />
                            </div>
                            <div class="ml-2">
                                <v-btn :disabled="loading" icon color="success" @click="agrega(2)">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                                Midriasis
                            </div>
                    </div>
                    <div class="d-flex pa-1 align-center">
                            <div>
                                <v-img
                                    height="50px"
                                    width="200"
                                    src="/images/escalas/miosis.jpg"
                                />
                            </div>
                            <div class="ml-2">
                                <v-btn :disabled="loading" icon color="success" @click="agrega(3)">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                                Miosis
                            </div>
                    </div>
                    <div class="d-flex pa-1 align-center">
                            <div>
                                <v-img
                                    height="50px"
                                    width="200"
                                    src="/images/escalas/anisocoria.jpg"
                                />
                            </div>
                            <div class="ml-2">
                                <v-btn :disabled="loading" icon color="success" @click="agrega(4)">
                                    <v-icon>mdi-check</v-icon>
                                </v-btn>
                                Anisocoria
                            </div>
                    </div>
                    
                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    <!--
                    <v-btn
                        color="success"
                        :loading="loadingSigno"
                        @click="dialog = false"
                    >
                        Agregar
                    </v-btn>
                    -->
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        loadingSigno:false,
        valor:0,
        observaciones:'',
        loading:false,
        
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                //console.log(value)
                this.$emit('update:dialogDe',val)
            },
        }
    },
    methods:{
        cancela(){
            this.observaciones = ''
            this.dialog = false
        },
        async agrega(val){
            
            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'reactividad',
                valor: val,
                internamiento:this.internamiento,
                observaciones: this.observaciones,
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.observaciones = ''
                    this.loading = false
                    this.dialog = false
                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }

            //console.log ('Actualizando reactividad en en turno '+this.turno+' con el valor '+val)
            
        },
    },
}
</script>

<style>

</style>